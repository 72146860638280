import React, { FC } from 'react'

type RankIconProps = {
  rank: number
  width?: number
  height?: number
}

export const RankIcon: FC<RankIconProps> = ({
  rank,
  width = 30.476,
  height = 31.989,
}) => {
  return (
    <div className="min-w-[16px] w-fit relative">
      <svg
        id="バッチ"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 30.476 31.989"
      >
        <path
          id="合体_1"
          data-name="合体 1"
          d="M14.021,31.576,3.061,23.195a3.05,3.05,0,0,1-2.046-2.88L0,3.046A3.047,3.047,0,0,1,3.046,0h24.38a3.048,3.048,0,0,1,3.048,3.047L29.458,20.315a3.05,3.05,0,0,1-2.046,2.88l-10.96,8.382a2,2,0,0,1-2.43,0Z"
          transform="translate(0.002 0.002)"
          fill="var(--color-main-blue)"
        />
      </svg>
      <div className="absolute top-[-2px] left-0 w-full h-full flex items-center justify-center text-white font-bold">
        {rank === 0 ? '-' : String(rank)}
      </div>
    </div>
  )
}
