import React, { FC, useEffect, useState } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import ExpoFVImage from './images/fv.png'
import { Helmet } from 'react-helmet'
import {
  ExpoCategoryLabel,
  ExpoCategoryValue,
  ExpoCategoryValues,
} from './category'
import Head2 from '../../components/heads/head2'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { EnterpriseRankingCard } from '../../components/card/enterprise_card'
import { Link, useParams } from 'react-router-dom'
import useEnterpriseExpoRanking from './use_enterprise_expo_ranking'

const ogpDescription =
  'クチコミ万博では、Osaka EXPO 2025の最新情報や参加者の評判・レビュー・評価を集め、海外＆国内パビリオン、グルメ、グッズ＆ショップ、イベントなど、万博をより深く楽しむための情報を提供します。実際の体験をもとに、万博の魅力を発見しましょう！'
const ogpKeywords =
  '万博,Expo,大阪,Osaka,クチコミ,クチコミ,レビュー,採点,評価,評判,カスタマーレビュー,コメント,ランキング,持続可能,サステナブル,Sustainable,ESG ,エシカル , ethical,Review'
const ogpTitle =
  'クチコミ万博 | Osaka EXPO 2025の評判・レビューで楽しむ海外＆国内パビリオン、グルメ＆イベント'

const ExpoCategoryPage: FC = () => {
  const isSp = useSpBreakPoint()
  const pathParams = useParams<{ category: string }>()
  // Initialize with URL parameter but then manage with state
  const [selectedCategory, setSelectedCategory] = useState<ExpoCategoryValue>(
    pathParams.category as ExpoCategoryValue
  )
  const [enterpriseRankings, isLoading, setIsAllLoaded, handler] =
    useEnterpriseExpoRanking({
      getLimit: 20,
      category: selectedCategory,
    })

  // Update URL without causing page reload (for bookmarking purposes)
  const updateUrlWithoutReload = (category: ExpoCategoryValue) => {
    const newUrl = `/kuchikomi-banpaku/${category}`
    window.history.replaceState({ path: newUrl }, '', newUrl)
  }

  // Handle category change
  const handleCategoryChange = (newCategory: ExpoCategoryValue) => {
    setSelectedCategory(newCategory)
    updateUrlWithoutReload(newCategory)
  }

  useEffect(() => {
    handler.reset(selectedCategory)
  }, [selectedCategory])

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Sharp|Material+Icons+Two+Tone"
          rel="stylesheet"
        />
        <meta name="description" content={ogpDescription} />
        <meta property="og:description" content={ogpDescription} />
        <meta name="twitter:description" content={ogpDescription} />
        <meta name="keywords" content={ogpKeywords} />
        <meta property="og:image" content={ExpoFVImage} />
        <meta name="twitter:image:src" content={ExpoFVImage} />
        <meta property="og:title" content={ogpTitle} />
        <meta name="twitter:title" content={ogpTitle} />
        <title>{ogpTitle}</title>
      </Helmet>

      <Link to="/kuchikomi-banpaku">
        <img src={ExpoFVImage} alt="expo" className="w-full mb-12" />
      </Link>
      <div className="mb-16">
        <h4 className="mb-4 font-bold text-2xl">グループ検索</h4>
        <div className="flex items-center flex-wrap gap-4">
          {ExpoCategoryValues.map((cate) => (
            <div
              key={cate}
              onClick={() => handleCategoryChange(cate)}
              className="cursor-pointer"
            >
              <div className="bg-main-darkblue py-4 w-52 text-center text-white font-bold text-base rounded-md">
                {ExpoCategoryLabel[cate]}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <div className="mb-8">
          <Head2 title={`${ExpoCategoryLabel[selectedCategory]}ランキング`} />
        </div>
        <div className="flex md:justify-between justify-center flex-wrap mb-4">
          {enterpriseRankings.map((item, index) => {
            return (
              <div
                key={`ranking-${selectedCategory}-${String(index)}`}
                className="md:w-[47.5%] w-full min-w-[280px] pb-4"
              >
                <EnterpriseRankingCard ranking={item} />
              </div>
            )
          })}
        </div>
        {!setIsAllLoaded && enterpriseRankings.length > 0 && (
          <button
            className="block mb-4 mx-auto w-full text-center bg-main-blue rounded-lg text-white max-w-[200px] py-3 text-base "
            onClick={handler.load}
          >
            もっと見る
          </button>
        )}
        {isLoading && <LoadingSpinner className="mx-auto" />}
      </div>

      {/* <FlatButton
        bgColor="blue"
        onClick={() => {
          navigate('/kuchikomi-banpaku')
        }}
        className="bg-main-darkblue"
      >
        万博TOPへ
      </FlatButton> */}
    </CommonTemplate>
  )
}

export default ExpoCategoryPage
