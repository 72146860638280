import React, { FC, ReactNode } from 'react'
import { HomeIcon } from '../../images/icons/home_icon'
import { SearchIcon } from '../../images/icons/search_icon'
import { ChartIcon } from '../../images/icons/chart_icon'
// import { BellIcon } from '../../images/icons/bell_icon'
import { Link } from 'react-router-dom'
import './navigation.css'
import { BellIcon } from '../../images/icons/bell_icon'

type BottomItemProps = {
  icon: ReactNode
  text: string
  linkTo: string
  onClick?: () => void
}

const SpNavigation: FC = () => {
  const navigation = (
    <div className="w-screen bg-white fixed bottom-0 z-10 border-t border-t-main-lightGray box-content spnavigation">
      <div className="flex justify-between p-2 pt-3 w-11/12 mx-auto">
        <BottomItem
          icon={<HomeIcon color="LIGHTGRAY" />}
          text="ホーム"
          linkTo="/"
        />
        <BottomItem
          icon={<SearchIcon color="LIGHTGRAY" />}
          text="検索"
          linkTo="/search/enterprise"
        />
        <BottomItem
          icon={<ChartIcon color="LIGHTGRAY" />}
          text="ランキング"
          linkTo="/ranking/enterprise"
        />
        <BottomItem
          icon={<BellIcon color="LIGHTGRAY" />}
          text="通知"
          linkTo="/mypage/notification"
        />
      </div>
    </div>
  )

  return navigation
}

const BottomItem: React.FC<BottomItemProps> = ({ icon, text, linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="mx-2 px-1 text-center cursor-pointer w-[70px]">
        <div className="flex justify-center h-6">{icon}</div>
        <p className="text-main-lightGray text-xs">{text}</p>
      </div>
    </Link>
  )
}

export default SpNavigation
