import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { EnterpriseRanking } from '../../model/Ranking'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { ExpoCategoryValue } from './category'

const DEFAULT_LIMIT = 100

type UseEnterpriseExpoRankingProps = {
  getLimit?: number
  category?: ExpoCategoryValue
}

type Handler = {
  load: () => Promise<void>
  reset: (category: ExpoCategoryValue | undefined) => void
}

const useEnterpriseExpoRanking = (
  props: UseEnterpriseExpoRankingProps
): [Array<EnterpriseRanking>, boolean, boolean, Handler] => {
  const limit = props.getLimit || DEFAULT_LIMIT
  const apiClient = getAppClient()
  const [category, setCategory] = useState<ExpoCategoryValue | undefined>(
    props.category
  )
  const { showSnackBar } = useSnackBar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [ranking, setRanking] = useState<Array<EnterpriseRanking>>([])

  const reset = useCallback((category: ExpoCategoryValue | undefined) => {
    setRanking([])
    setApiCursor(0)
    setIsAllLoaded(false)
    setCategory(category)
  }, [])

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.ranking
      // .enterpriseList({
      //   cursor: apiCursor,
      //   limit: limit,
      // })
      .enterpriseExpoList({
        cursor: apiCursor,
        limit: limit,
        category: category,
      })
      .then((res) => {
        const data = res.data.data
        const nextRanking = ranking.concat(data)
        setRanking(nextRanking)
        setApiCursor(apiCursor + limit)
        if (data.length < limit) {
          setIsAllLoaded(true)
        }
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, ranking, category])

  useEffect(() => {
    load()
  }, [category])

  return [ranking, isLoading, isAllLoaded, { load: load, reset: reset }]
}

export default useEnterpriseExpoRanking
