import React, { FC } from 'react'
import { EvalurationStarIcon } from '../../images/icons/star_icon'
import { Link } from 'react-router-dom'
import { EnterpriseRanking } from '../../model/Ranking'
import { RankIcon } from '../../images/icons/rank_icon'
import Enterprise from '../../model/Enterprise'

type EnterpriseCardProps = {
  isSp?: boolean
  enterprise: Enterprise
}

type EnterpriseRankingCardProps = {
  isSp?: boolean
  ranking: EnterpriseRanking
}

const EnterpriseCard: FC<EnterpriseCardProps> = ({ enterprise }) => {
  return (
    <Link to={'/enterprises/' + enterprise.id}>
      <div className="bg-white py-6 px-4 rounded-xl">
        <div>
          <p className="text-xl text-main-black font-bold">{enterprise.name}</p>
        </div>
        <div className="lg:flex justify-start pb-4">
          <div className="flex justify-start">
            <div className="flex flex-col justify-center pr-2">
              <EvalurationStarIcon evaluation={enterprise.evaluationAverage} />
            </div>
            <p className="text-main-black">
              {enterprise.evaluationAverage
                ? enterprise.evaluationAverage.toFixed(2)
                : '-'}
            </p>
          </div>
          <div className="flex justify-start lg:pl-2">
            <p className="text-xs text-main-black leading-8">クチコミ件数</p>
            <p className="text-xs text-main-black leading-8 pl-2">
              {enterprise.kuchikomiCount
                ? enterprise.kuchikomiCount.toLocaleString()
                : '-'}
            </p>
          </div>
        </div>
        <div className="opacity-50">
          {enterprise.enterpriseCode && (
            <div className="flex justify-start mb-1">
              <p className="text-sm text-main-black mr-4">
                {enterprise.enterpriseCode.value}
              </p>
              <p className="text-sm text-main-black font-bold">
                {enterprise.enterpriseRank ? (
                  <>
                    <span>{enterprise.enterpriseRank.rank}位</span>
                    {enterprise.enterpriseRank.total && (
                      <span className="text-xs pl-3">
                        /{enterprise.enterpriseRank.total}位
                      </span>
                    )}
                  </>
                ) : (
                  '圏外'
                )}
              </p>
            </div>
          )}
          {enterprise.industryParentCode && (
            <div className="flex justify-start mb-1">
              <p className="text-sm text-main-black mr-4">
                {enterprise.industryParentCode.value}
              </p>
              <p className="text-sm text-main-black font-bold">
                {enterprise.industryRank ? (
                  <>
                    <span>{enterprise.industryRank.rank}位</span>
                    {enterprise.industryRank.total && (
                      <span className="text-xs pl-3">
                        /{enterprise.industryRank.total}位
                      </span>
                    )}
                  </>
                ) : (
                  '圏外'
                )}
              </p>
            </div>
          )}
          <div className="flex justify-start mb-1">
            <p className="text-sm text-main-black mr-4">都道府県</p>
            <p className="text-sm text-main-black font-bold">
              {enterprise.prefectureRank ? (
                <>
                  <span>{enterprise.prefectureRank.rank}位</span>
                  {enterprise.prefectureRank.total && (
                    <span className="text-xs pl-3">
                      /{enterprise.prefectureRank.total}位
                    </span>
                  )}
                </>
              ) : (
                '圏外'
              )}
            </p>
          </div>
          {enterprise.sdgsRank &&
            enterprise.sdgsRank.map((sdgsRank, index) => (
              <div className="flex justify-start" key={index}>
                <p className="text-sm text-main-black mr-4">
                  {sdgsRank.category.value}
                </p>
                <p className="text-sm text-main-black font-bold">
                  {sdgsRank.rank}位
                  {sdgsRank.total && (
                    <span className="text-xs pl-3">/{sdgsRank.total}位</span>
                  )}
                </p>
              </div>
            ))}
        </div>
      </div>
    </Link>
  )
}

export default EnterpriseCard

export const EnterpriseRankingCard: FC<EnterpriseRankingCardProps> = (
  props
) => {
  return (
    <div className="relative">
      <div className="flex flex-col justify-center absolute -top-2 -left-2">
        <RankIcon rank={props.ranking.rank || 0} />
      </div>
      <EnterpriseCard isSp={props.isSp} enterprise={props.ranking.enterprise} />
    </div>
  )
}
